const BookmarkSVG = ({ isBookmark }: { isBookmark: boolean }) => {
  return (
    <svg
      className="focus:outline-none text-gray-800"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill={isBookmark ? '#ef4444' : 'none'}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M10.5001 4.66667H17.5001C18.1189 4.66667 18.7124 4.9125 19.15 5.35009C19.5876 5.78767 19.8334 6.38117 19.8334 7V23.3333L14.0001 19.8333L8.16675 23.3333V7C8.16675 6.38117 8.41258 5.78767 8.85017 5.35009C9.28775 4.9125 9.88124 4.66667 10.5001 4.66667Z"
        stroke={isBookmark ? '#ef4444' : '#4B5563'}
        strokeWidth="1.25"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default BookmarkSVG;
